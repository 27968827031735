import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import { publishPost } from '../../../Api/axios';
import AutoResizeTextarea from '../../AutoResizeTextarea';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { FaImage } from "react-icons/fa";

const FacebookForm = forwardRef(({ messageProps, updateMessage, fileUrls }, ref) => {
  const [message, setMessage] = useState(messageProps || '');
  const [showConfirm, setShowConfirm] = useState(false);
  const [resultModal, setResultModal] = useState({ show: false, message: '' });

  const handleInputChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    updateMessage('facebook', newMessage);
  };

  const handleConfirm = () => {
    if (message && message.trim() !== '') {
      setShowConfirm(true);
    }
  };

  const handlePost = async () => {
    setShowConfirm(false);

    try {
      const res = await publishPost({
        message_type: 'facebook',
        message: message,
        file_urls: fileUrls.filter(file => file.facebook).map(file => file.file_url) // Filtre les fichiers pour Facebook
      });

      // Si le statut est 201 et que le post_id est valide
      if (res.status === 201 && res.data.post_id) {
        setResultModal({ show: true, message: 'Votre message Facebook a été publié' });
      } else {
        // Affiche le message d'erreur renvoyé par le serveur
        const errorMessage = res.data?.error || 'Une erreur s\'est produite lors de la publication.';
        setResultModal({ show: true, message: errorMessage });
      }
    } catch (error) {
      // Capture l'erreur et affiche-la dans le modal
      const errorMessage = error.response?.data?.error || 'Une erreur s\'est produite lors de la publication';
      setResultModal({ show: true, message: errorMessage });
    }
  };


  const handleCancel = () => {
    setShowConfirm(false);
  };

  useEffect(() => {
    setMessage(messageProps || '');
  }, [messageProps]);

  useImperativeHandle(ref, () => ({
    submit: handlePost
  }));

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="mb-3">
        <h3>Facebook:</h3>
        <div className="form-group">
          <AutoResizeTextarea
            type="text"
            className="form-control"
            value={message}
            onChange={handleInputChange}
            style={{ minHeight: '100px' }}
          />
        </div>
        {fileUrls && fileUrls.filter(file => file.facebook).map((file, index) => (
          <div key={index} className="mb-3 file-item">
            <FaImage /> <p>&nbsp;</p>
            <span>{file.file_url.split('/').pop()}</span>
            <div>
              <label>
                <input type="checkbox" checked={file.facebook} onChange={(e) => {
                  const updatedFileUrls = [...fileUrls];
                  updatedFileUrls[index].facebook = e.target.checked;
                  updateMessage('file_urls', updatedFileUrls);
                }} />
                &nbsp;Poster sur Facebook
              </label>
            </div>
          </div>
        ))}
        <Button className='post-btn' onClick={handleConfirm} disabled={!message || !message.trim()}>Poster</Button>
      </form>

      <Modal show={showConfirm} onHide={() => setShowConfirm(false)} centered>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>Êtes-vous sûr de vouloir publier ce message ?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCancel}>Non</Button>
          <Button variant="primary" onClick={handlePost}>Oui</Button>
        </Modal.Footer>
      </Modal>

      <Modal show={resultModal.show} onHide={() => setResultModal({ ...resultModal, show: false })} centered>
        <Modal.Body>{resultModal.message}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setResultModal({ ...resultModal, show: false })}>
            Fermer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
});

export default FacebookForm;
