import React from 'react';
import './nav.css';


const NavBar = () => {
  /*return (
    <header className="navbar">
      <script src="https://kit.fontawesome.com/a076d05399.js" crossorigin="anonymous"></script>
      <button className="menu-button">
        <span>
            <div className="menu-icon"></div>
            <div className="menu-icon"></div>
            <div className="menu-icon"></div>
        </span>
        <i> Menu</i>
      </button>
      <a href="https://www.bus-artis.fr/" title="Artis : Retourner sur la page d'accueil">
        <img src="https://storage.googleapis.com/is-wp-119-prod/uploads-prod/2023/07/cropped-logo.png"
            alt="Artis : Retourner sur la page d'accueil" className="logo" />
      </a>
    </header>
  );*/
}
export default NavBar;