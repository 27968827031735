import React, { useState, useEffect, useImperativeHandle, forwardRef } from 'react';
import AutoResizeTextarea from '../../AutoResizeTextarea';
import Button from 'react-bootstrap/Button';

const WebsiteForm = forwardRef(({ messageProps, updateMessage }, ref) => {
  const [message, setMessage] = useState(messageProps || '');

  const handleInputChange = (e) => {
    const newMessage = e.target.value;
    setMessage(newMessage);
    updateMessage('website', newMessage);
  };

  useEffect(() => {
    setMessage(messageProps || '');
  }, [messageProps]);

  useImperativeHandle(ref, () => ({
    submit: () => {
      // Dummy post function
    }
  }));

  return (
    <div>
      <form onSubmit={(e) => e.preventDefault()} className="mb-3">
        <h3>Site web et App Transvilles:</h3>
        <div className="form-group">
          <AutoResizeTextarea
            type="text"
            className="form-control"
            value={message}
            onChange={handleInputChange}
            style={{ minHeight: '100px' }}
          />
        </div>
        <Button className='post-btn' disabled>
          Poster
        </Button>
      </form>
    </div>
  );
});

export default WebsiteForm;
