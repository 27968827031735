import React from 'react';

const WebsiteEmbed = () => {
  return (
    <div className="website-embed">
      {/* You can add any additional components or styling needed for embedding the website post here */}
    </div>
  );
};

export default WebsiteEmbed;
