import React, { useState, useEffect } from 'react';
import { generateMessage } from '../../../Api/axios';
import Modal from 'react-bootstrap/Modal';
import Select from 'react-select';
import Papa from 'papaparse';
import { FaTimes, FaImage } from 'react-icons/fa';

function GenerateMessageOrganism({ messages, setMessages }) {
  const [routes, setRoutes] = useState([]);
  const [trips, setTrips] = useState([]);
  const [stopTimes, setStopTimes] = useState([]);
  const [stops, setStops] = useState([]);
  const [selectedLines, setSelectedLines] = useState([]);
  const [filteredStops, setFilteredStops] = useState([]); // Updated: filtered stops state
  const [selectedStop, setSelectedStop] = useState('');
  const [selectedDeviation, setSelectedDeviation] = useState('');
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [inputs, setInputs] = useState({
    busLine: '',
    busStop: '',
    incidentMessage: ''
  });
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    const fetchAndParse = async (filename) => {
      const response = await fetch(process.env.PUBLIC_URL + `/${filename}`);
      const text = await response.text();
      return Papa.parse(text, { header: true, skipEmptyLines: true }).data;
    };

    Promise.all([
      fetchAndParse('routes.txt'),
      fetchAndParse('trips.txt'),
      fetchAndParse('stop_times.txt'),
      fetchAndParse('stops.txt')
    ]).then(([routeData, tripData, stopTimeData, stopData]) => {
      setRoutes(routeData);
      setTrips(tripData);
      setStopTimes(stopTimeData);
      setStops(stopData);
    });
  }, []);

  const updateFilteredStops = (selectedLines) => {
    if (selectedLines.length === 1) {
      const selectedRoute = selectedLines[0];
      if (selectedRoute) {
        const routeTrips = trips.filter(trip => trip.route_id === selectedRoute.route_id);
        const routeStopIds = new Set(routeTrips.flatMap(trip =>
          stopTimes.filter(st => st.trip_id === trip.trip_id).map(st => st.stop_id)
        ));
        const newStops = stops.filter(stop => routeStopIds.has(stop.stop_id));
        setFilteredStops(newStops);
        if (!newStops.some(stop => stop.stop_name === selectedStop)) {
          setSelectedStop('');
        }
      } else {
        setFilteredStops([]);
      }
    } else {
      setFilteredStops([]);
      setSelectedStop('');
    }
  };

  const handleLineChange = (selectedOptions) => {
    const newSelectedLines = selectedOptions.map(option =>
      routes.find(route => route.route_short_name === option.value)
    );
    setSelectedLines(newSelectedLines);
    updateFilteredStops(newSelectedLines);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setInputs(prev => ({ ...prev, [name]: value }));
  };

  const handleFileChange = (e) => {
    const newFiles = Array.from(e.target.files).map(file => ({
      file,
      facebook: true,
      twitter: true
    }));
    setSelectedFiles([...selectedFiles, ...newFiles]);
  };

  const handleRemoveFile = (index) => {
    const updatedFiles = selectedFiles.filter((_, i) => i !== index);
    setSelectedFiles(updatedFiles);
    document.getElementById('files').value = '';
    setMessages(prevMessages => ({
      ...prevMessages,
      file_urls: prevMessages.file_urls.filter((_, i) => i !== index)
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);

    const formData = new FormData();
    selectedLines.forEach(line => {
      formData.append('bus_lines', line.route_long_name);
    });

    formData.append('bus_stop', selectedStop);
    formData.append('incident_message', inputs.incidentMessage);
    formData.append('deviation_type', selectedDeviation.value);

    selectedFiles.forEach(file => {
      formData.append('files', file.file);
    });

    generateMessage(formData).then(res => {
      if (res.data) {
        setMessages(prevMessages => ({
          ...prevMessages,
          twitter: res.data.messages.twitter,
          facebook: res.data.messages.facebook,
          website: res.data.messages.website,
          file_urls: res.data.file_urls.map((url, i) => ({
            file_url: url,
            facebook: selectedFiles[i].facebook,
            twitter: selectedFiles[i].twitter
          }))
        }));
      }
      setIsLoading(false);
    }).catch(error => {
      console.error('Error when sending to backend:', error.message);
      setIsLoading(false);
    });
  };

  const deviationOptions = [
    { value: 'simple', label: 'Déviation Simple' },
    { value: 'inopinee', label: 'Déviation Inopinée' },
    { value: 'plan_neige', label: 'Plan Neige' }
  ];

  return (
    <div className="inner-container">
      <form onSubmit={handleSubmit} className="mb-3">
        <div className="form-group">
          <label htmlFor="busLine" className="form-label">Ligne de Bus:</label>
          <Select
            isMulti
            name="busLine"
            options={routes.map(route => ({ value: route.route_short_name, label: route.route_long_name }))}
            className="basic-multi-select"
            classNamePrefix="select"
            onChange={handleLineChange}
            value={selectedLines.map(line => ({ value: line.route_short_name, label: line.route_long_name }))}
          />
        </div>
        <br />

        <div className="form-group">
          <label htmlFor="busStop" className="form-label">Arrêt de Bus:</label>
          <input
            required
            type="text"
            className="form-control"
            id="busStop"
            name="busStop"
            list="bus-stops"
            value={selectedStop}
            onChange={e => setSelectedStop(e.target.value)}
            placeholder="Arrêt"
            disabled={selectedLines.length !== 1}
          />
          <datalist id="bus-stops">
            {filteredStops.map((stop, index) => (
              <option key={index} value={stop.stop_name} />
            ))}
          </datalist>
        </div>
        <br />

        <div className="form-group">
          <label htmlFor="deviationType" className="form-label">Type de Déviation:</label>
          <Select
            name="deviationType"
            options={deviationOptions}
            className="basic-single-select"
            classNamePrefix="select"
            onChange={setSelectedDeviation}
            value={selectedDeviation}
          />
        </div>
        <br />

        <div className="form-group">
          <label htmlFor="incidentMessage" className="form-label">Message d'Incident:</label>
          <textarea
            className="form-control"
            id="incidentMessage"
            name="incidentMessage"
            required
            value={inputs.incidentMessage}
            onChange={handleChange}
          ></textarea>
        </div>

        <div className="form-group">
          <label htmlFor="files" className="form-label">Ajouter des fichiers:</label>
          <input
            type="file"
            className="form-control"
            id="files"
            name="files"
            multiple
            onChange={handleFileChange}
          />
          <div className="uploaded-files">
            {selectedFiles.map((file, index) => (
              <div key={index} className="file-item">
                <FaImage /> <p>&nbsp;</p>
                <span>{file.file.name}</span>
                <button type="button" onClick={() => handleRemoveFile(index)} className="remove-btn">
                  <FaTimes />
                </button>
              </div>
            ))}
          </div>
        </div>
        <br />

        <button type="submit" className="mb-3 generate-btn" disabled={isLoading}>Générer Messages</button>
      </form>
      <Modal show={isLoading} centered>
        <Modal.Body>
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="sr-only"></span>
            </div>
            <p>Génération des messages en cours...</p>
          </div>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default GenerateMessageOrganism;
