import React, { forwardRef } from 'react';
import WebsiteEmbed from './post';
import WebsiteForm from './form';

const WebsiteOrganism = forwardRef(({ message, updateMessage }, ref) => (
  <div className="inner-container">
    <WebsiteForm ref={ref} messageProps={message} updateMessage={updateMessage} />
    <WebsiteEmbed />
  </div>
));

export default WebsiteOrganism;
