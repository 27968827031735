import axios from "axios";
import { POSTS_API, MESSAGE_GENERATION_API } from "../settings/api";

export const generateMessage = (data) => axios.post(`${MESSAGE_GENERATION_API}`, data, {
  headers: {
    'Content-Type': 'multipart/form-data'
  }
});
export const fetchPosts = (params) => axios.get(`${POSTS_API}`, { params: params });
export const publishPost = (data) => axios.post(`${POSTS_API}`, data);
export const deletePost = (data) => axios.delete(`${POSTS_API}`, { data });
