import React, { useState, useEffect } from 'react';
import { fetchPosts, deletePost } from '../../../Api/axios';
import { Tweet } from 'react-tweet';

function TwitterEmbed() {
  const [postId, setPostId] = useState('');

  useEffect(() => {
    fetchPosts({message_type: 'twitter'}).then(res => {
      // Check if data is available and has at least one item
      if (res.data && res.data.length > 0) {
        setPostId(res.data[0].post_id); // Ensure there is an item in the array
      } else {
        console.error('No posts found or empty data returned:', res.data);
      }
    }).catch(error => {
      console.error('Error fetching posts:', error.message);
    });
  }, []);
  
  const handleSubmit = async (e) => {
    e.preventDefault();
    deletePost({ data: { post_id: postId } }).then(res => {
      // Check if data is available and has at least one item
      if (res.data && res.data.length > 0) {
        setPostId(res.data[0].post_id); // Reset the postId with new one if available
      } else {
        console.error('No posts found or empty data returned after delete:', res.data);
      }
    }).catch(error => {
      console.error('Error deleting post:', error.message);
    });
  };

  return (""
    /*<form onSubmit={handleSubmit}>
      <div data-theme="dark">
        {/* Check if postId is not empty to render the Tweet component }
        /*{postId && <Tweet id={postId} />}
      </div>
      <input style={{ background: 'red', float: 'right' }} type="submit" value="Delete" />
    </form>*/
  );
}

export default TwitterEmbed;
