import React, { forwardRef } from 'react';
import FacebookEmbed from './post';
import FacebookForm from './form';

const FacebookOrganism = forwardRef(({ message, updateMessage, fileUrls }, ref) => (
  <div className="inner-container">
    <FacebookForm ref={ref} messageProps={message} updateMessage={updateMessage} fileUrls={fileUrls} />
    <FacebookEmbed />
  </div>
));

export default FacebookOrganism;
