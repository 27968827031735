import React, { forwardRef } from 'react';
import TwitterEmbed from './post';
import TwitterForm from './form';

const TwitterOrganism = forwardRef(({ message, updateMessage, fileUrls }, ref) => (
  <div className="inner-container">
    <TwitterForm ref={ref} messageProps={message} updateMessage={updateMessage} fileUrls={fileUrls} />
    <TwitterEmbed />
  </div>
));

export default TwitterOrganism;
