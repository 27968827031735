import React, { useRef, useEffect } from 'react';

function AutoResizeTextarea({ value, onChange }) {
    const textareaRef = useRef(null);

    const adjustHeight = () => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'inherit'; // Reset height to recalculate
            const computed = window.getComputedStyle(textareaRef.current);
            const height = parseInt(computed.getPropertyValue('border-top-width'), 10)
                         + parseInt(computed.getPropertyValue('padding-top'), 10)
                         + textareaRef.current.scrollHeight
                         + parseInt(computed.getPropertyValue('padding-bottom'), 10)
                         + parseInt(computed.getPropertyValue('border-bottom-width'), 10);
            textareaRef.current.style.height = `${height}px`;
        }
    };

    // Adjust height when 'value' changes
    useEffect(() => {
        adjustHeight();
    }, [value]);

    // Add window resize listener to adjust textarea height
    useEffect(() => {
        window.addEventListener('resize', adjustHeight);
        return () => window.removeEventListener('resize', adjustHeight);
    }, []);

    return (
        <textarea
            ref={textareaRef}
            className="form-control"
            value={value}
            onChange={onChange}
            style={{ overflow: 'hidden', resize: 'none' }}
        />
    );
}

export default AutoResizeTextarea;
