import React, { useState, useEffect } from 'react';
import { fetchPosts, deletePost } from '../../../Api/axios';

function FacebookEmbed() {
  const [postId, setPostId] = useState('');

  useEffect(() => {
    fetchPosts({message_type: 'facebook'}).then(res => {
      if (res.data && res.data.length > 0) {
        setPostId(res.data[0].post_id); // Ensure there is an item in the array
      } else {
        console.error('No posts found or empty data returned:', res.data);
      }
    }).catch(error => {
      console.error('Error fetching posts:', error.message);
    });
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    deletePost({ data: { post_id: postId } }).then(res => {
      if (res.data && res.data.length > 0) {
        setPostId(res.data[0].post_id); // Ensure there is an item in the array after deletion
      } else {
        console.error('No posts found or empty data returned after delete:', res.data);
      }
    }).catch(error => {
      console.error('Error deleting post:', error.message);
    });
  };
  const facebookPostUrl = `https://www.facebook.com/${postId.split('_')[0]}/posts/${postId.split('_')[1]}`;
  return ( ""
    /*<form onSubmit={handleSubmit}>
      <iframe
        title='facebook post'
        src={`https://www.facebook.com/plugins/post.php?href=${encodeURIComponent(facebookPostUrl)}&show_text=true&width=500&is_preview=true`}
        width="450"
        height="250"
        style={{ border: 'none', overflow: 'hidden' }}
        scrolling="no"
        frameborder="0"
        allowfullscreen="true"
        allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
      ></iframe>
      <input style={{background: 'red', float: 'right'}} type="submit" value="Delete" />
    </form>*/
  );
}

export default FacebookEmbed;
